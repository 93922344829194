<template>
  <v-dialog v-model="innerValue" transition="slide-y-transition" persistent scrollable max-width="680px">
    <v-card>
      <v-card-title class="grey lighten-4">
        <app-id-icon />
        <span class="font-weight-bold tertiary--text title">
          {{ form.title }}
        </span>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="grey lighten-2" :disabled="loading" fab depressed small @click="onCloseClick">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("buttons.close") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="pa-0">
        <component ref="form" :is="form.component" :travel="travel" :loading="loading" :status="innerValue" />
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-3">
        <v-spacer />
        <v-btn color="primary" :loading="loading" depressed @click="onSaveClick">{{ $t("buttons.send") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { FormTypeEnum } from "../enums";

  export default {
    name: "CreateDailog",
    props: {
      value: Boolean,
      travel: Object,
      formType: {
        validator(value) {
          return !value || Object.values(FormTypeEnum).indexOf(value) !== -1;
        }
      }
    },
    components: {
      ExpenseForm: () => import("./ExpenseForm"),
      ImprestForm: () => import("./ImprestForm"),
      SubsistenceForm: () => import("./SubsistenceForm")
    },
    data: vm => ({
      loading: false,
      forms: {
        [FormTypeEnum.EXPENSE]: {
          title: vm.$t("hr.travel.adding_expense"),
          component: "ExpenseForm"
        },
        [FormTypeEnum.IMPREST]: {
          title: vm.$t("hr.travel.adding_imprest"),
          component: "ImprestForm"
        },
        [FormTypeEnum.SUBSISTENCE]: {
          title: vm.$t("hr.travel.adding_subsistence"),
          component: "SubsistenceForm"
        },
        [FormTypeEnum.DEBIT]: {
          title: vm.$t("hr.travel.adding_debit"),
          component: "DebitForm"
        }
      }
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      },
      form() {
        return this.formType ? this.forms[this.formType] : {};
      }
    },
    methods: {
      onSaveClick() {
        this.loading = true;
        this.$refs.form
          .validateAndSave()
          .then(saved => {
            if (saved) {
              this.$emit("save");
              this.innerValue = false;
            }
          })
          .catch(console.error)
          .finally(() => (this.loading = false));
      },
      onCloseClick() {
        this.innerValue = false;
      }
    }
  };
</script>
